export const route = '/judges';

// const JudgePageRoute: IRouteProps = {
//   path: route,
//   exact: true,
//   component: lazy(() => import('./JudgePages')),
//   name: 'JudgePage',
// };

// export default JudgePageRoute;
