import styled from 'styled-components';
import { keyframes } from 'styled-components';
export const hide = keyframes`
0%{
  opacity: 1;

}
100%{
  opacity: 0;
}
`;
interface IProps {
  path: string;
}
export const HeaderWrapper = styled.div<IProps>`
  width: 100%;
  color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  font-family: 'Montserrat';
  font-style: normal;
  z-index: 998;
  display: flex;
  align-items: center;
  justify-content: center;
  .header-container {
    border-bottom: 2px solid rgb(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    padding: 10px 38px 6px 100px;
    background-color:  ${(props) => {
      switch (props.path) {
        case '/nfts':
          return 'rgb(0, 0, 0, 0.3)';
        case '/mystery-box':
          return 'rgb(0, 0, 0, 0.7)';
      }
    }};
    
    width: 100%;
    ${(props) => props.theme.mediaWidth.upToSmall`
    padding: 24px;
    `}
    ${(props) => props.theme.mediaWidth.upToExtraSmall1`
    padding: 12px;
    `}
    justify-content: space-between;
    .left {
      ${(props) => props.theme.mediaWidth.upToMedium1`
        justify-content: space-between;
        width: 50%;
         `}
      ${(props) => props.theme.mediaWidth.upToExtraSmall1`
      `}
      ${(props) => props.theme.mediaWidth.upToExtraSmall2`
        width: 63%;
      `}
      display: flex;
      .logo {
        transition: all .3s;
        cursor: pointer;
        &:hover {
        transform: scale(1.07);
        }
        img {
         width: 83px;
          height: 68px;
          ${(props) => props.theme.mediaWidth.upToExtraSmall1`
          width: 67px;
          height: 46px;
         `}
        }
      }
    }
    .btn-show-nav-mobile {
      display: none;
      align-items: center;
      ${(props) => props.theme.mediaWidth.upToMedium1`
        display : flex; `}
    }
  }
  .right {
    display: flex;
    column-gap: 27px;
    align-items: center;
    ${(props) => props.theme.mediaWidth.upToExtraSmall`
      column-gap: 12px;
      `}
    .search {
      ${(props) => props.theme.mediaWidth.upToMedium1`
        display : none; `}
    }
    .account-container {
      position: relative;
      &:hover .account-menu {
        opacity: 1;
        visibility: visible;
      }
      .avatar {
        width: 40px;
        cursor: pointer;
        border-radius: 50%;
      }
      .account-menu {
        margin-top: 5px;
        opacity: 0;
        padding: 20px;
        width: 280px;
        visibility: hidden;
        position: absolute;
        background-color: black;
        border: 1.5px solid #2e2e2e;
        top: 100%;
        right: 0;
        box-shadow: 0px 0px 30px rgba(18, 18, 19, 0.9);
        border-radius: 16px;
        transition: all 0.4s;
        .account {
          display: flex;
          flex-direction: row;
          .detail {
            display: flex;
            flex-direction: column;
            margin-left: 12px;
            .address {
              font-weight: 600;
              font-size: 16px;
            }
            .username {
              color: #ff42ca;
              font-weight: 400;
              font-size: 16px;
            }
          }
        }
        .line {
          border: 0.2px dashed rgba(255, 255, 255, 0.2);
          margin: 15px 0px;
          width: 100%;
        }
        .item {
          font-family: 'Montserrat' ;
          color: white;
          text-transform: capitalize;
          font-size: 16px;
          font-size: 16px;
          justify-content: flex-start;
        }
      }
    }
    .network-container {
      position: relative;
      ${(props) => props.theme.mediaWidth.upToExtraSmall2`
      display : none;
      `}
      &:hover .chain {
        opacity: 1;
        visibility: visible;
      }
      .network {
        border: 1px solid #6a6c7b;
        border-radius: 20px;
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 16px;
        text-transform: capitalize;
        color: white;
      }
      .chain {
        margin-top: 5px;
        opacity: 0;
        visibility: hidden;
        width: 150px;
        position: absolute;
        background-color: black;
        border: 1.5px solid #2e2e2e;
        top: 100%;
        left: 50%;
        transform: translate(-50%, 0);
        box-shadow: 0px 0px 30px rgba(18, 18, 19, 0.9);
        border-radius: 16px;
        transition: all 0.4s;
        .item {
          font-family: 'Montserrat' ;
          padding: 10px 10px;
          color: white;
          text-transform: capitalize;
          font-size: 16px;
          
        }
      }
    }

    .menu {
      ${(props) => props.theme.mediaWidth.upToMedium1`
        display : none; `}
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        column-gap: 24px;
          li {
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            cursor: pointer;
            position: relative;
          #tooltip0{
            position: absolute;
            top: 24px;
            display: none;
            align-items: center;
            justify-content: center;
            padding: 8px;
            animation: ${hide} 2s forwards;
          }
          #tooltip1{
            border: 1px solid #57595E;
            border-radius: 50px;
            position: absolute;
            top: 24px;
            display: none;
            align-items: center;
            justify-content: center;
            padding: 8px;
            animation: ${hide} 2s forwards;
          }
          #tooltip2{
            border: 1px solid #57595E;
            border-radius: 50px;
            position: absolute;
            top: 24px;
            display: none;
            align-items: center;
            justify-content: center;
            padding: 8px;
            animation: ${hide} 2s forwards;
          }
          }
      }
      }
      .connect-wallet {
        padding: 12px 20px;
        border: 1px solid #6a6c7b;
        border-radius: 20px;
        display: flex;
        align-items: center;
        column-gap: 8px;
        cursor: pointer;
        ${(props) => props.theme.mediaWidth.upToExtraSmall`
        padding: 8px;
        `}
        ${(props) => props.theme.mediaWidth.upToExtraSmall1`
        span{
          font-size: 11px;
            }
          `}
      }
    }
  }
`;
