import styled, { ITheme } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  padding-top: 150px;
  overflow-x: hidden;
  ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
           padding-top: 60px;
        `}
  .content {
    position: relative;
  }

  .coming-soon {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    letter-spacing: 6px;

    span {
      font-family: 'Noto Serif';
      font-style: normal;
      font-weight: 400;
      font-size: 64px;
      color: #ffffff;
      white-space: nowrap;
      ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToMedium`
          font-size: 50px;
        `}
      ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
          font-size: 32px;
        `}
    }
  }
  .info {
    padding: 60px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    justify-content: center;

    span {
      font-family: 'Noto Serif';
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      color: #ffffff;
      white-space: nowrap;
      transition: all 0.3s;
      text-align: center;
      span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: rgb(255, 255, 255);
        opacity: 0.5;
        white-space: nowrap;
      }
      /* &:hover {
        transform: scale(1.1);
        color: #ff42ca;
      } */
      ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToMedium`
          font-size: 52px;
        `}
      ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
          font-size: 40px;
        `}

${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall1`
          font-size: 32px;
        `}
    }
    .connect-dot {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 33px;
      div {
        width: 42px;
        /* height: 40px; */
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        svg {
          font-size: 46px;
          transition: all 0.3s;
          &:hover {
            transform: scale(1.1);
          }
          ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
          font-size: 26px;
        `}
        }

        /* background: #ffffff; */
        /* opacity: 0.3; */
        /* border-radius: 16px; */
        ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
           width: 22px;
        
        `}
        img {
          width: 90%;
          transition: all 0.3s;
          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
  }
`;
