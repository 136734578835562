import { lazy } from 'react';
import { IRouteProps } from '../index';

export const route = '/our-story';

const OurStoryRoute: IRouteProps = {
  path: route,
  exact: true,
  component: lazy(() => import('../OurStory')),
  name: 'OurStory',
};

export default OurStoryRoute;
