import React from 'react';
import { Wrapper } from './NavigationMobile.styled';
import { list } from 'components/Header/Header';
import { useHistory } from 'react-router-dom';
export const NavigationMobile = ({ setVisibility }: any) => {
  const history = useHistory();
  return (
    <Wrapper id="navigation_mobile">
      <div className="overlay" onClick={() => setVisibility(false)}></div>
      <div className="content">
        <ul>
          {list.map((item, key) => {
            return (
              <li
                onClick={() => {
                  history.push(item.path);
                  setVisibility(false);
                }}
                key={key}
              >
                {item.content}
              </li>
            );
          })}
        </ul>
      </div>
    </Wrapper>
  );
};
export default NavigationMobile;
