import { IRouteProps } from 'modules';
import { lazy } from 'react';

export const route = '/nfts';
export const ListtingNFTsRoute: IRouteProps = {
  path: '/voting',
  name: 'Voting',
  component: lazy(() => import('./Voting')),
  exact: true,
};
export default ListtingNFTsRoute;
