import styled, { ITheme } from 'styled-components';
export const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 46px 120px 32px 120px;
  color: white;
  border-top: 1px solid rgb(255, 255, 255, 0.05);
  ${(props) => props.theme.mediaWidth.upToMedium`
  padding: 32px;
  `}
  .top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${(props) => props.theme.mediaWidth.upToMedium2`
    flex-direction: column;
    row-gap: 48px;
    `}
    .left {
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        column-gap: 32px;
        li {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
    .logo-middle {
      width: 116px;
      height: 96px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .right {
      display: flex;
      column-gap: 33px;
      .square {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        svg {
          font-size: 46px;
          transition: all 0.3s;
          &:hover {
            transform: scale(1.1);
          }
          ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
          font-size: 26px;
        `}
        }

        /* background: #ffffff; */
        /* opacity: 0.3; */
        /* border-radius: 16px; */
        ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
           width: 22px;
            // height: 20px;
        `}
        img {
          width: 90%;
          transition: all 0.3s;
          &:hover {
            transform: scale(1.1);
          }
        }
      }
      .tiktok {
        width: 40px;
        height: 40px;
        ${(props) => props.theme.mediaWidth.upToExtraSmall`
        width: 22px;
        height: 22px;
        `}
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
  .bottom {
    width: 100%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    opacity: 0.5;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`;
