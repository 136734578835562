import { lazy } from 'react';
import { IRouteProps } from '../index';

export const route = '/news/:newsId';

const NewsPageRoute: IRouteProps = {
  path: route,
  exact: true,
  component: lazy(() => import('./NewsPage')),
  name: 'NewsPage',
};

export default NewsPageRoute;
