import { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { ENVS } from 'configs/Configs.env';
import { useChangeChain } from 'services/hooks/useWalletProvider';
import { useWallet } from 'use-wallet';
import { isMobile } from 'react-device-detect';
import { onMoveAnimation } from 'services/hooks/useDevelopUI';

export const WalletProviders = ({ children }: { children: any }) => {
  const wallet = useWallet();
  const isFirstTimeRender = useRef(true);
  const handleChangeChain = useChangeChain();
  useEffect(() => {
    if (isMobile && window?.ethereum) {
      wallet.connect('injected');
    }
  }, []);
  useEffect(() => {
    if (wallet.account) {
      if (!isFirstTimeRender.current) {
        onMoveAnimation('connect-wallet-overlay', 'moveOutOpacity');
      }
      isFirstTimeRender.current = false;
    }
  }, [wallet.account]);
  useEffect(() => {
    if (wallet.status === 'connected') {
      if (
        !ENVS.REACT_APP_BASE_CHAIN_ID.includes(wallet.chainId?.toString() || '')
      ) {
        handleChangeChain(parseInt(ENVS.REACT_APP_BASE_CHAIN_ID[0]));
        toast.info('Hmm! Look like you connected incompatible network');
      }
    }
  }, [wallet?.status, wallet.error?.name, wallet?.chainId]);
  return children;
};
