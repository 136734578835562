import { IRouteProps } from 'modules';
import { lazy } from 'react';

export const route = '/nfts';
export const ListtingNFTsRoute: IRouteProps = {
  path: '/nfts',
  name: 'Listting NFTs',
  component: lazy(() => import('./ListtingNFTs')),
  exact: true,
};
export default ListtingNFTsRoute;
