import React from 'react';
import { FooterWrapper } from './Footer.styled';
import Logo from 'assets/Header/logo-yellow.png';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import Tiktok from 'assets/Home/section9/tiktok.png';
import LanguageIcon from '@mui/icons-material/Language';
export const Footer = () => {
  return (
    <FooterWrapper>
      <div className="top">
        {/* <div className="left">
          <ul>
            {menuList.map((item, key) => {
              return <li key={key}>{item}</li>;
            })}
          </ul>
        </div> */}
        <div className="logo-middle">
          <img src={Logo} alt="logo" />
        </div>
        <div className="right">
          <div
            className="square"
            onClick={() => {
              window.open(
                'https://www.youtube.com/c/MissCharmOfficial',
                'blank',
              );
            }}
          >
            <YouTubeIcon />
          </div>
          <div
            className="square"
            onClick={() =>
              window.open('https://www.facebook.com/MISSCHARM.TV', 'blank')
            }
          >
            <FacebookIcon />
          </div>
          <div
            className="square"
            onClick={() => {
              window.open('https://www.instagram.com/misscharm.tv/', 'blank');
            }}
          >
            <InstagramIcon />
          </div>
          <div
            className="square"
            onClick={() => {
              window.open('https://twitter.com/MisscharmTV', 'blank');
            }}
          >
            <TwitterIcon />
          </div>
          <div className="square">
            <img
              src={Tiktok}
              alt=""
              className="tiktok"
              onClick={() =>
                window.open(
                  'https://www.tiktok.com/@misscharmofficial?_t=8YIxajnTah0&_r=1',
                )
              }
            />
          </div>
          <div
            className="square"
            onClick={() => window.open('https://misscharm.tv/')}
          >
            <LanguageIcon />
          </div>
        </div>
      </div>
      <div className="bottom">@2023 MISS CHARM. ALL RIGHTS RESERVED.</div>
    </FooterWrapper>
  );
};
export default Footer;
