export interface IWallet {
  name: string;
  path: string;
  connector: string;
  description: string;
}

export const WALLET: IWallet[] = [
  {
    name: 'Fizen Super App',
    path: 'fizen.png',
    connector: 'walletconnect',
    description: 'Mobile Wallet',
  },
  {
    name: 'Metamask',
    path: 'metamask.png',
    connector: 'injected',
    description: 'Mobile Wallet and Extension',
  },
  {
    name: 'Trust Wallet',
    path: 'trustwallet.png',
    connector: 'injected',
    description: 'Mobile Wallet',
  },
  {
    name: 'Wallet Connect',
    path: 'walletconnect.png',
    connector: 'walletconnect',
    description: 'Connector',
  },
  {
    name: 'Coinbase',
    path: 'coinbase.png',
    connector: 'walletlink',
    description: 'Mobile Wallet and Extension',
  },
];
