import { Container } from 'components/Container/Container';
import React from 'react';

import { Wrapper } from './ComingSoon.styled';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
export const ComingSoon = () => {
  return (
    <Wrapper>
      <Container>
        <div className="coming-soon">
          <span>COMING SOON</span>
          <div></div>
        </div>
        <div className="info">
          <span>
            Connect with us <br /> <span>for more infomation</span>
          </span>

          <div className="connect-dot">
            <div
              onClick={() => {
                window.open('https://www.youtube.com/c/MissCharmOfficial');
              }}
            >
              <YouTubeIcon />
            </div>
            <div
              onClick={() =>
                window.open('https://www.facebook.com/MISSCHARM.TV')
              }
            >
              <FacebookIcon />
            </div>
            <div
              onClick={() => {
                window.open('https://www.instagram.com/misscharm.tv/');
              }}
            >
              <InstagramIcon />
            </div>
            <div
              onClick={() => {
                window.open('https://twitter.com/missblockcharm');
              }}
            >
              <TwitterIcon />
            </div>
          </div>
        </div>
      </Container>
    </Wrapper>
  );
};

export default ComingSoon;
