import styled from 'styled-components';
import { keyframes } from 'styled-components';
const anim = keyframes`
0%{
  opacity: 0;
}
100%{
  opacity: 1;
}
`;
export const Wrapper = styled.div`
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 800px;
  align-items: center;
  justify-content: center;
  animation: ${anim} 0.3s forwards;
  // transform-origin: center center;
  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background-color: transparent;
  }
  .content {
    z-index: 2;
    position: absolute;
    border: 1.5px solid #2e2e2e;
    background: black;
    box-shadow: 0px 0px 30px rgba(18, 18, 19, 0.9);
    border-radius: 16px;
    left: 12px;
    top: 92px;
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    color: white;
    ${(props) => props.theme.mediaWidth.upToMedium1`
    left: 92px;
    width:30%;
    top: 464px;

    `}
    ${(props) => props.theme.mediaWidth.upToMedium2`
    left: 22px;
    width:30%;
    top: 464px;

    `}
    ${(props) => props.theme.mediaWidth.upToExtraSmall`
    left: 12px;
    top: 424px;


    `}
    ul {
      width: 100%;
      list-style: none;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 0;
      padding: 0;
      row-gap: 24px;
      li {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        ${(props) => props.theme.mediaWidth.upToSmall`
        font-size: 12px;
    `}
      }
    }
  }
`;
