import React from 'react';
import { CrossmintPayButton } from '@crossmint/client-sdk-react-ui';

interface IPropCrossMintButton {
  totalPrice: string;
  amount: string;
}
export const CrossMintButton = ({
  totalPrice,
  amount,
}: IPropCrossMintButton) => {
  return (
    <CrossmintPayButton
      clientId={process.env.REACT_APP_CROSSMINTID as string}
      mintConfig={{
        type: 'erc-721',
        totalPrice: totalPrice?.toString(),
        amount: amount?.toString(),
      }}
      environment={!!process.env.REACT_APP_ISTESTING ? 'staging' : ''}
      className="xmint-btn"
    />
  );
};
