import React from 'react';
import { HeaderWrapper } from './Header.styled';
import LogoYellow from 'assets/Header/logo-yellow.png';
import Wallet from 'assets/Header/wallet_image.png';
import NavigationMobile from './NavigationMobile/NavigationMobile';
import Dehaze from '@mui/icons-material/Dehaze';
import withWallet from 'hoc/withWallet';
import { Button, MenuItem } from '@mui/material';
import { SUPPORTED_NETWORKS_ARRAY } from 'configs/Connect/Configs.networks';
import { LoadingButton } from '@mui/lab';
import { formatAddressToHuman } from 'services/utilities/format';
import { getScannerAddress } from 'services/helper';
import { useHistory, useLocation } from 'react-router-dom';
import { useGetProfile } from 'queries';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { deepOrange } from '@mui/material/colors';

type MenuItem = {
  path: string;
  content: string;
};
export const list: Array<MenuItem> = [
  {
    path: '/',
    content: 'HOME',
  },
  {
    path: '/nfts',
    content: 'NFTs',
  },
  // {
  //   path: '/lottery-box',
  //   content: 'LOTTERY BOX',
  // },
  // {
  //   path: '/beauty-nft-sale',
  //   content: 'BEAUTY NFT SALE',
  // },
  // {
  //   path: '/sponsor',
  //   content: 'SPONSOR',
  // },
  // {
  //   path: '/news',
  //   content: 'NEWS',
  // },
  // {
  //   path: '/contact',
  //   content: 'CONTACT',
  // },
  // {
  //   path: '/follow',
  //   content: 'FOLLOW',
  // },
  {
    path: '/voting',
    content: 'VOTING',
  },
];
export const HeaderComponent = ({
  onOpenConnectModal,
  account,
  chain,
  changeChain,
  disconnect,
  token,
}: any) => {
  // const ShowNavMobile = () => {
  //   const navmobile = document.querySelector(
  //     '#navigation_mobile',
  //   ) as HTMLElement;
  //   navmobile.style.display = 'flex';
  // };
  const history = useHistory();
  const { pathname } = useLocation();
  const { data: profile, isFetching } = useGetProfile(token);
  const [imgCount, setImgCount] = React.useState(new Date().getTime());
  const [isShowMobileMenu, setIsShowMobileMenu] = React.useState(false);
  React.useEffect(() => {
    if (isFetching) {
      let time = new Date().getTime();
      setImgCount(time);
    }
  }, [isFetching]);

  return (
    <HeaderWrapper {...{ path: pathname }}>
      {isShowMobileMenu && (
        <NavigationMobile setVisibility={setIsShowMobileMenu} />
      )}
      <div className="header-container">
        <div className="left">
          <div
            className="btn-show-nav-mobile"
            onClick={() => setIsShowMobileMenu(true)}
          >
            <Dehaze /> Menu
          </div>
          <div className="logo" onClick={() => history.push('/')}>
            <img src={LogoYellow} alt="logo header" />
          </div>
        </div>
        <div className="right">
          <div className="menu">
            <ul>
              {list.map((item, key) => {
                return (
                  <li
                    key={key}
                    onClick={() => {
                      history.push(item.path);
                    }}
                  >
                    {item.content}
                  </li>
                );
              })}
            </ul>
          </div>
          {/* <div className="search">
            <SearchIcon />
          </div> */}

          {!account ? (
            <div className="connect-wallet" onClick={onOpenConnectModal}>
              <img
                src={Wallet}
                alt="wallet"
                style={{ width: '18px', height: ' 18px' }}
              />
              <span> Connect Wallet</span>
            </div>
          ) : (
            <>
              <div className="network-container">
                <Button variant="outlined" className="network">
                  {SUPPORTED_NETWORKS_ARRAY.find(
                    (network) => network.chainNumb === chain?.chainId,
                  )?.chainName || 'Network not supported'}
                </Button>
                <div className="chain">
                  {SUPPORTED_NETWORKS_ARRAY.map(
                    (network, index) =>
                      network.chainNumb !== chain?.chainId && (
                        <LoadingButton
                          key={index}
                          className="item"
                          onClick={() => changeChain(network.chainNumb)}
                          fullWidth
                        >
                          {network.chainName}
                        </LoadingButton>
                      ),
                  )}
                </div>
              </div>
              <div className="account-container">
                {profile?.avatar ? (
                  <img
                    className="avatar"
                    src={profile?.avatar + `?random=${imgCount}`}
                    alt=""
                  />
                ) : (
                  <Stack
                    direction="row"
                    spacing={2}
                    style={{
                      width: '40px',
                      height: '40px',
                    }}
                  >
                    <Avatar
                      sx={{ bgcolor: deepOrange[500] }}
                      style={{
                        width: '40px',
                        height: '40px',
                      }}
                    >
                      {profile?.username ? profile?.username[0] : 'OP'}
                    </Avatar>
                  </Stack>
                )}
                <div className="account-menu">
                  <div className="account">
                    {profile?.avatar ? (
                      <img
                        className="avatar"
                        src={profile?.avatar + `?random=${imgCount}`}
                        alt=""
                      />
                    ) : (
                      <Stack
                        direction="row"
                        spacing={2}
                        style={{
                          width: '40px',
                          height: '40px',
                        }}
                      >
                        <Avatar
                          sx={{ bgcolor: deepOrange[500] }}
                          style={{
                            width: '40px',
                            height: '40px',
                          }}
                        >
                          {profile?.username ? profile?.username[0] : 'OP'}
                        </Avatar>
                      </Stack>
                    )}
                    <div className="detail">
                      <span className="address">
                        {formatAddressToHuman(account)}
                      </span>
                      <span className="username">
                        {profile?.username || 'MissBlockCharm User'}
                      </span>
                    </div>
                  </div>
                  <div className="line"></div>
                  <Button
                    onClick={() =>
                      window.open(
                        getScannerAddress(chain?.chainId, account),
                        '_blank',
                      )
                    }
                    fullWidth
                    className="item"
                  >
                    View on Scanner
                  </Button>
                  <Button
                    onClick={() => history.push('/profile?type=nfts')}
                    fullWidth
                    className="item"
                  >
                    View your inventory
                  </Button>
                  <Button
                    onClick={() => history.push('/profile')}
                    fullWidth
                    className="item"
                  >
                    View your profile
                  </Button>
                  <Button onClick={disconnect} fullWidth className="item">
                    Disconnect
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </HeaderWrapper>
  );
};

export const Header = withWallet(HeaderComponent);

export default Header;
