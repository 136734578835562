import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import customHttp from 'services/utilities/customHttp';
import http from 'services/utilities/http';
import { ENVS } from 'configs/Configs.env';

export const handleGetProfile = async () => {
  const response = await http.get('/profile');
  return response;
};

export const handleSendVerifyCode = async (email: string) => {
  try {
    const response = await http.get(
      `/profile/request-link-email?email=${email}`,
    );
    toast.success(`Code sent to your email ${email}`);
    return response;
  } catch (err: any) {
    toast.error(err?.message || err);
  }
};

// export const handleLinkCurrentAccountToEmail = async (
//   email: string,
//   code: string,
//   password: string,
//   confirmPassword,
// ) => {
//   try {
//     const response = await http.put('/profile/link-email', {
//       email,
//       code,
//       password,
//       confirmPassword,
//     });
//     return response;
//   } catch (err: any) {
//     toast.error(err?.message || err);
//   }
// };
export const handleRequestLinkWallet = async () => {
  try {
    const response: any = await http.get('/profile/request-link-wallet');
    return response;
  } catch (err: any) {
    toast.error(err?.message || err);
  }
};

export const handleLinkWallet = async (account: any, code: string) => {
  try {
    const response: any = await http.put('/profile/link-wallet', {
      address: account?.toString(),
      chainId: parseInt(ENVS.REACT_APP_BASE_CHAIN_ID[0]),
      code,
    });
    return response;
  } catch (err: any) {
    toast.error(err?.message || err);
  }
};

// Hooks of accounts

export const useGetProfile = (token: any) => {
  return useQuery(
    ['useGetProfile.name'],
    async () => {
      const response: any = await http.get(`/v1/profile`);
      return response;
    },
    {
      refetchInterval: 8000,
      retry: false,
      enabled: !!token,
    },
  );
};

export const useGetUserActivities = (address: any) => {
  return useQuery(
    ['useGetUserActivities.name'],
    async () => {
      const response: any = await http.get(
        `/v2/dashboard/${address}/activity?take=500&pageIndex=0`,
      );
      return response;
    },
    {
      refetchInterval: 8000,
    },
  );
};

export const handleChangePassword = async (
  oldPassword: string,
  newPassword: string,
  newPasswordConfirm: string,
) => {
  try {
    const response = await http.put('/profile/change-password', {
      oldPassword,
      newPassword,
      newPasswordConfirm,
    });
    return response;
  } catch (err: any) {
    toast.error(err?.message || err);
  }
};
function DataURIToBlob(dataURI: any) {
  const splitDataURI = dataURI.split(',');
  const byteString =
    splitDataURI[0].indexOf('base64') >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
}
export const handleUploadAvatar = async (avatar: any) => {
  const file = DataURIToBlob(avatar.getImage().toDataURL());

  const formData = new FormData();
  formData.append('avatar', file, 'image.jpg');
  let count = 0;
  async function checkFlag() {
    if (formData.getAll('avatar')?.length === 0) {
      window.setTimeout(checkFlag, 100);
      count++;
    } else {
      if (count === 1000) return;
    }
  }
  checkFlag();

  try {
    const response = await http.put('/v1/profile/avatar/upload', formData, {
      headers: { 'Content-type': 'multipart/form-data' },
    });
    return response;
  } catch (err: any) {
    toast.error(err?.message || err);
  }
};
export const handleChangeProfileInfo = async (
  username: string,
  avatar: string,
) => {
  try {
    const response = await http.put('/v1/profile', {
      username,
      avatar,
    });
    return response;
  } catch (err: any) {
    toast.error(err?.message || err);
  }
};

export const useGetUserInventory = (
  address: string | undefined,
  take: number,
  pageIndex: number,
  orderBy: string,
  sortOrder: string,
) => {
  return useQuery(
    ['useGetUserInventory.name'],
    async () => {
      const response: any = await http.get(
        `/v1/miss/${address}?take=${take}&pageIndex=${pageIndex}`,
      );
      return response;
    },
    {
      enabled: !!address,
      refetchInterval: 10000,
      retry: false,
    },
  );
};

export const useGetUserMysteryBox = (
  address: string | undefined,
  take: number,
  pageIndex: number,
  orderBy: string,
  sortOrder: string,
  type: string,
) => {
  return useQuery(
    ['useGetUserMysteryBox.name', type],
    async () => {
      const response: any = await http.get(
        `/v1/blindbox/${address}?take=${take}&pageIndex=${pageIndex}&type=${type}`,
      );
      return response;
    },
    {
      enabled: !!address,
      refetchInterval: 10000,
      retry: false,
    },
  );
};

export const useGetLotteryMetadata = (id: string) => {
  return useQuery(
    ['useGetLotteryMetadata.name', id],
    async () => {
      const response: any = await customHttp.get(`/v1/blindbox/metadata/${id}`);
      return response;
    },
    {
      enabled: !!id,
      refetchInterval: 10000,
      retry: false,
    },
  );
};

export const useGetMissMetadata = (id: string) => {
  return useQuery(
    ['useGetMissMetadata.name', id],
    async () => {
      const response: any = await customHttp.get(`/v1/miss/metadata/${id}`);
      return response;
    },
    {
      enabled: !!id,
      refetchInterval: 10000,
      retry: false,
    },
  );
};
