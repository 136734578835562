import React, { FunctionComponent, useCallback } from 'react';
import {
  useChangeChain,
  useConnectWallet,
  useCheckAccountActive,
  useCheckChain,
  useLogout,
  useCheckChainConnected,
} from 'services/hooks/useWalletProvider';
import { onMoveAnimation } from 'services/hooks/useDevelopUI';
import { useAppDispatch } from 'app/store';
import { selectToken, signInByWallet } from 'reducers';
import { useWallet } from 'use-wallet';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const withConnect =
  (WrappedComponent: FunctionComponent) => (props: any) => {
    function useGetSelectQuery() {
      const { search } = useLocation();

      return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useGetSelectQuery();
    const token = useSelector(selectToken);

    const wallet = useWallet();
    const connect = useConnectWallet();
    const disconnect = useLogout();
    const changeChain = useChangeChain();
    const account = useCheckAccountActive();
    const { data: chain } = useCheckChain();
    const isConnectedCompatibleChain = useCheckChainConnected();

    const onOpenConnectModal = useCallback(() => {
      onMoveAnimation('connect-wallet-overlay', 'moveInOpacity');
    }, [onMoveAnimation]);

    const onCloseConnectModal = useCallback(
      () => onMoveAnimation('connect-wallet-overlay', 'moveOutOpacity'),
      [onMoveAnimation],
    );
    const dispatch = useAppDispatch();
    const onSigninWallet = useCallback(
      () =>
        dispatch(
          signInByWallet({
            address: account,
            provider: wallet?.ethereum,
            refferal: query.get('refferal') || undefined,
          }),
        ),
      [account, wallet?.ethereum],
    );

    const onCheckBeforeRequest = useCallback(async () => {
      if (!account) {
        onOpenConnectModal();
        return false;
      }
      if (!token) {
        onSigninWallet();
        return false;
      }

      return true;
    }, [token, account]);

    return (
      <WrappedComponent
        {...{
          ...props,
          connect,
          changeChain,
          account,
          chain,
          onCloseConnectModal,
          onOpenConnectModal,
          disconnect,
          isConnectedCompatibleChain,
          onSigninWallet,
          token,
          onCheckBeforeRequest,
        }}
      />
    );
  };

export default withConnect;
