import styled from 'styled-components';
import { keyframes } from 'styled-components';
export const anim_scale = keyframes`
0%{
  transform: scale(1.1);
}
`;
export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  z-index: 9999999999;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 1s;
  overflow: visible;
  .logo {
    &:hover {
      transform: scale(1.2);
    }
    width: 187px;
    height: 176px;
    animation: fade 0.7s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    ${(props) => props.theme.mediaWidth.upToMedium`
    
    width: 97px;
    height: 86px;
    `}
  }
`;
