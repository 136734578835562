export const route = '/airdrop-heaven';
import { IRouteProps } from 'modules';
import { lazy } from 'react';
const AirDropHeavenRoute: IRouteProps = {
  path: route,
  component: lazy(() => import('./AirDropHeaven')),
  exact: true,
  name: 'AirDropHeaven',
};
export default AirDropHeavenRoute;
