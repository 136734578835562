import { lazy } from 'react';
import { IRouteProps } from '../index';

const route = '/*';

export const ComingSoonRoute: IRouteProps = {
  path: route,
  exact: false,
  component: lazy(() => import('.')),
  name: 'ComingSoon',
};
