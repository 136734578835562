import React from 'react';
import { Provider as ProviderRedux } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { store } from './store';
import { QueryClientProvider } from 'react-query';
import { queryClient } from '../services/utilities/queryClient';
import { ToastContainer } from 'react-toastify';
import { MainRoute } from '../modules';
import { BrowserRouter, BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, ThemedGlobalStyle } from '../reducers/theme';
import { Header, Footer, ConnectForm } from 'components';
import { UseWalletProvider } from 'use-wallet';
import { connectors } from 'configs/Connect/Configs.rpc';
import '../styles/animate.css';
import {
  AccessTokenProvider,
  LoadingProvider,
  WalletProviders,
} from 'providers';
import ScrollToTop from 'components/ScrollTop/ScrollTop';

let persistor = persistStore(store);

export const App = () => {
  return (
    <ProviderRedux store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider>
          <ThemedGlobalStyle />
          <QueryClientProvider client={queryClient}>
            <UseWalletProvider connectors={connectors} autoConnect={true}>
              <BrowserRouter>
                <WalletProviders>
                  <LoadingProvider>
                    <AccessTokenProvider>
                      <Router>
                        <ScrollToTop />
                        <ConnectForm />
                        <Header />
                        <MainRoute />
                        <ToastContainer
                          toastStyle={{
                            backgroundColor: 'black',
                            fontFamily: 'Montserrat',
                          }}
                          position="bottom-right"
                          autoClose={5000}
                          hideProgressBar={false}
                          newestOnTop={false}
                          closeOnClick
                          pauseOnFocusLoss
                          pauseOnHover
                        />
                      </Router>
                    </AccessTokenProvider>
                  </LoadingProvider>
                </WalletProviders>
              </BrowserRouter>
              <Footer />
            </UseWalletProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </PersistGate>
    </ProviderRedux>
  );
};
