import { IRouteProps } from 'modules';
import { lazy } from 'react';
export const route = '/lottery-box';

const MysteryBoxRoute: IRouteProps = {
  path: route,
  exact: true,
  component: lazy(() => import('../MysteryBox')),
  name: 'MysteryBox',
};

export default MysteryBoxRoute;
